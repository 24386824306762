/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A pricing intent is a template that you use to set the fees that your merchants pay. Each pricing intent covers three categories of fees:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Base fees"), " - Fees for additional features, which include:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Address verification service (AVS)"), "\n", React.createElement(_components.li, null, "Security add-ons"), "\n", React.createElement(_components.li, null, "Portal access"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Gateway fees"), " - Fees for using our gateway, which include:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Access fees"), "\n", React.createElement(_components.li, null, "Setup fees"), "\n", React.createElement(_components.li, null, "Tokenization fees"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Processor fees"), " – Fees that cover the cost to process each transaction. These fees depend on the pricing program that the merchant signs up to, for example interchange plus or flat rate."), "\n"), "\n", React.createElement(_components.p, null, "Before you board a merchant, they must sign a merchant processing agreement (MPA) that describes the fees that the merchant has agreed to pay. Use the information from the MPA to populate the fees in the pricing intent."), "\n", React.createElement(_components.p, null, "You can create a pricing intent for each merchant, or assign a merchant to a pricing intent that you have already created."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
